import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Teams from "../components/Teams"
import Intro from "../components/Intro"
import SEO from "../components/SEO"

const AdvisorsPage = ({
  data: {
    allContentfulAdvisors: { nodes: advisor },
    contentfulIntroAdvisor
  },
}) => {
  const AdvisorIntro = contentfulIntroAdvisor;
  return (
  <Layout>
    <SEO title="Advisor" description="Meet and learn about the High Rock Capital Advisor."/>
    <div className="advisor-page">
      <div className="section-center-narrow">
        <Intro data={AdvisorIntro} slide/>
      </div>
      <div>
      <div className="section-color-gray team-list">
        <section className="section-center-narrow">
          <Teams team={advisor} title="Meet your advisors" list/>
        </section>
      </div>
      </div>
    </div>
  </Layout>
  );
}

export const query = graphql`
{
  allContentfulAdvisors(sort: {order: ASC, fields: createdAt}) {
    nodes {
      id
      name
      teaser {
        teaser
      }
    }
  }
  contentfulIntroAdvisor {
    title
    subTitle
    description {
      description
    }
  }
}
`

export default AdvisorsPage
