import React from "react";

const Intro = ({data}) => {
    const text = data.description.description;
    return <header className={`intro section-centre section-padding`}>
            <h4 className="subTitle">{data.subTitle}</h4>
            <h1 className="title">{data.title}</h1>
            <p>{text}</p>
    </header>
}

export default Intro